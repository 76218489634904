import React from 'react';
import Errorpage from '../../../components/section-components/error';

const NotFound = () => {
    return(
        <div>
            <Errorpage />
        </div>
    )
}

export default NotFound;