import React, { useEffect, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import { UserSignup } from '../../../apis/PostMethods';
import { emailValidation,passwordValidation } from '../../../utils/Validations';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { login } from '../../../config/LoginAuth';
import { Link } from 'react-router-dom';
import { AsYouType } from 'libphonenumber-js';

const Signup = () => {
    const [loading, setLoading] = useState(false);
    const [mobileNo, setMobileNo] = useState(null);

    let publicUrl = process.env.PUBLIC_URL+'/'
    useEffect(() => {
        const $ = window.$;
        $(window).on('load', function () {
                   var preLoder = $("#preloader");
     preLoder.fadeOut(0);

       });
    },[])

    const handleSignup = (e) => {
        e.preventDefault();
        const first_name = document.getElementById("Fname").value;
        const last_name = document.getElementById("Lname").value;
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;
        const ph_num = document.getElementById("mobile-number").value;
        const user_type_id = 2;

        if(emailValidation(email)){
            if(passwordValidation(password)) {
                setLoading(true);
                const obj = {first_name, last_name, email, password, ph_num, user_type_id}
                UserSignup(obj).then(doc => {
                    if(doc.code === 1){
                        login(doc)
                        setLoading(false);
                    }
                    else {
                        document.getElementById("error-label").innerHTML = doc.message;
                        setLoading(false);
                    }
                })
                .catch(e =>{
                    document.getElementById("error-label").innerHTML = e.message;
                    setLoading(false);
                })
            }
            else {
                document.getElementById("error-label").innerHTML = "Password must be atleast eight characters long including atleast one letter and one number.";
            }
        }
        else {
            document.getElementById("error-label").innerHTML = "Invalid email format";
        }
    }

    return <>
        <Container fluid>
            <Row>
                <Col md="7" className="px-0 d-none d-md-block">
                    <div className="brand-wrapper">
                        <Link to="/">
                            <img src={publicUrl + 'assets/img/sticky-logo.png'} alt="Vamonos logo" className="logo img-fluid"/>
                        </Link>
                    </div>
                    <img src={publicUrl + 'assets/img/signinBg.jpg'} alt="Asota island" className="login-img"/>
                </Col>


                <Col md="5" id="vamonos-signup">
                    <div className="login-wrapper ">
                        <h1 className="login-title" style={{fontFamily:"gilroy_bold"}}>Register Now</h1>
                        <p style={{fontFamily:"proxima_nova_regular"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempus, sem vel vehicula interdum</p>
                        
                        <Form onSubmit={(e) => handleSignup(e)}>
                            <FormGroup>
                                <Row>
                                    <Col xs="6">
                                        <Label for="Fname"><PersonIcon style={{marginBottom : '-6px' , fontSize :  "18px"}}/>First Name</Label>
                                        <Input type="text" id="Fname" placeholder="Ashar" required
                                            onKeyPress={()=>{document.getElementById("error-label").innerHTML=""}}
                                        />
                                    </Col>

                                    <Col xs="6">
                                        <Label for="Lname"> <PersonIcon style={{marginBottom : '-6px', fontSize :  "18px"}}/>Last Name</Label>
                                        <Input type="text"  id="Lname" placeholder="Ashfaq" required
                                            onKeyPress={()=>{document.getElementById("error-label").innerHTML=""}}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label for="mobile-number"> <PhoneIcon style={{marginBottom : '-6px' , fontSize :  "18px"}}/>Mobile Number</Label>
                                <Input type="text" name="mobile-number" id="mobile-number" placeholder="+92-XXXXXXX" value={mobileNo}
                                    onChange={(e) => {setMobileNo(new AsYouType('PK').input(e.target.value) )}}
                                    onKeyPress={()=>{document.getElementById("error-label").innerHTML=""}}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email"><EmailIcon style={{marginBottom : '-3px ' , fontSize :  "15px"}}/>Email </Label>
                                <Input type="email"  id="email" placeholder="ashar@gmail.com" required
                                    onKeyPress={() => { document.getElementById("error-label").innerHTML = ""; } }
                                />
                            </FormGroup>
                        {/* <div className="row" id="radio">
                                <div className="col-4">
                                    <div class="form-check">
                                        <label class="form-check-label" for="male">
                                            <input type="radio" class="form-check-input" id="male" name="optradio" />Male
                                        </label>
                                    </div>
                                </div>
                            
                                <div className="col-4">
                                    <div class="form-check">
                                        <label class="form-check-label" for="female">
                                            <input type="radio" class="form-check-input" id="female" name="optradio" />Female
                                        </label>
                                    </div>
                                </div>

                            
                        </div> */}
                        {/* <div className="form-group ">
                        <input type="date" name="date" id="email" className="form-control" placeholder="date"/>
                        </div> */}
                            <FormGroup style={{marginBottom: '0px'}}>
                                <Label for="password"> <LockIcon style={{marginBottom : '-2px', fontSize :  "15px"}}/>Password</Label>
                                <Input type="password"  id="password" placeholder="•••••••••••" required
                                    onKeyPress={()=>{document.getElementById("error-label").innerHTML=""}}
                                />
                            </FormGroup>
                            
                            <div id="error-label" className="text-danger" style={{fontSize: '14px'}}></div>

                            <Row className="mt-4">
                                <Col xs="12">
                                    <Button type="submit" className="btn login-btn btn-block" disabled={loading}>{loading ? "Please wait..." : "Register Now"}</Button>
                                </Col>

                                <Col xs="12">
                                    <p style={{fontFamily:"proxima_nova_regular"}}>Already have an account?
                                        <a href="/login"> Login</a>
                                    </p>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>    
    </>
}
export default Signup;