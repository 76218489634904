import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PublicRoute } from './navigation/RouteTypes';
import CommingSoon from './pages/ComingSoon';
import Login from './pages/Login'
import Signup from './pages/Signup'

import DefaultLayout from './reusables/DefaultLayout';
import '../src/scss/style.scss'
import ForgetPassword from './pages/ForgetPassword/forgetpassword';
import Changepassword from './pages/ForgetPassword/changepassword';

//fallback loading
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const firebase = require('firebase').default
var firebaseConfig = {
    apiKey: "AIzaSyBjobWnTdPnO7MSn14841WDXwGpFSVu7g0",
    authDomain: "vamonos-6fb56.firebaseapp.com",
    projectId: "vamonos-6fb56",
    storageBucket: "vamonos-6fb56.appspot.com",
    messagingSenderId: "813053768295",
    appId: "1:813053768295:web:dfabd3594610a52a5f1ac2",
    measurementId: "G-JW8P342304"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();



class Root extends Component {
    render() {
        return(
			<Router basename="/">
				<Suspense fallback={loading()}>
					<Switch>
						<PublicRoute exact={true} restricted={false} path="/comming-soon" component={CommingSoon} />
						<PublicRoute exact={true} restricted={true} path="/login" component={Login} />
						<PublicRoute exact={true} restricted={true} path="/register" component={Signup} />
                        <PublicRoute exact={true} restricted={true} path='/forgotpassword' component={ForgetPassword}/>
                        <PublicRoute exact={true} restricted={true} path='/changepassword' component={Changepassword}/>
						<Route path="/" render={(props) => <DefaultLayout {...props} />} />
						
					</Switch>
				</Suspense>
			</Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('viaje'));
