import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Button, Label, Input } from 'reactstrap';

import LockIcon from '@material-ui/icons/Lock';
import { changePassword } from '../../apis/PostMethods';


import { Link,useHistory } from 'react-router-dom';


const Changepassword = (props) => {
    const [loading, setLoading] = useState(false);
const history=useHistory();
    let publicUrl = process.env.PUBLIC_URL+'/'

    useEffect(() => {
        const $ = window.$;
    
        $(window).on('load', function () {
            console.log("chala");
            var preLoder = $("#preloader");
            preLoder.fadeOut(0);

       });
    },[])
    const changepassword=(e)=>{
        e.preventDefault()
    let password = document.getElementById('password').value
    let cPassword = document.getElementById('cpassword').value;
    if (password == cPassword) {
        setLoading(true);
        changePassword({email:props.location.state,password:password}).then(res => {
          console.log(res)
          setLoading(false);
          if (res.code === 1) {
              alert("done")
            history.push('/login')
          }
          else {
            document.getElementById('error-label').innerHTML = res.message;
          }
        }).catch(err => {
          setLoading(false);
          document.getElementById("error-label").innerHTML = err.message;
        }) 
    }
    else {
      document.getElementById("error-label").innerHTML = "password and confirm password are not same";
      setLoading(false);
    }
  }


  

    return <>
        <Container fluid>
            <Row>
                <Col md="7" className="px-0 d-none d-md-block">
                    <div className="brand-wrapper">
                        <Link to="/">
                            <img src={publicUrl + 'assets/img/logo.png'} alt="Vamonos logo" className="logo img-fluid"/>
                        </Link>
                    </div>
                    <img src={publicUrl + 'assets/img/loginBg.jpg'} alt="Ushu forest" className="login-img"/>
                </Col>

                <Col md="5" id="vamonos-signup">
                    <div className="login-wrapper">
                        <h1 className="login-title" style={{fontFamily:"gilroy_bold"}}>Change Password</h1>
                        <p style={{fontFamily:"proxima_nova_regular"}}>Requested to change the password</p>
                        
                        <Form onSubmit={(e) => changepassword(e)}>

                            <FormGroup className="mb-2">
                                <Label for="password"><LockIcon style={{marginBottom : '-2px', fontSize :  "15px"}}/>Password</Label>
                                <Input type="password" name="password" id="password" placeholder="•••••••••••" required
                                    onKeyPress={() => { document.getElementById("error-label").innerHTML = ""; }}
                                />
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label for="Confirm password"><LockIcon style={{marginBottom : '-2px', fontSize :  "15px"}}/>Confirm Password</Label>
                                <Input type="password" name="confirm password" id="cpassword" placeholder="•••••••••••" required
                                    onKeyPress={() => { document.getElementById("error-label").innerHTML = ""; }}
                                />
                            </FormGroup>
                            <div id="error-label" className="text-danger" style={{fontSize: '14px'}}></div>
                            <Button className="login-btn" type="submit" color="info" block disabled={loading ? true : false}>{loading ? "Changing..." : "Change"}</Button>
                           
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
}
export default Changepassword;