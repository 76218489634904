import React from "react";

const Home = React.lazy(() => import('../pages/Home'));
const About = React.lazy(() => import('../pages/About'));
const Contact = React.lazy(() => import('../pages/Contact'));
const Blog = React.lazy(() => import('../pages/Blog'));
const BlogDetails = React.lazy(() => import('../pages/BlogDetails'));
const TourList = React.lazy(() => import('../pages/TourList'));
const TourDetails = React.lazy(() => import('../pages/TourDetails'));
const SelfPlanner = React.lazy(() => import('../pages/SelfPlanner/component/SelfPlanner'));
// const TourList = React.lazy(() => import('../pages/TourList/component/TourList'));
const TourList2 = React.lazy(() => import('../pages/TourList/component/TourListV2'));
const TourList3 = React.lazy(() => import('../pages/TourList/component/TourListV3'));
// const TourDetails = React.lazy(() => import('../pages/TourDetails/component/TourDetails'));
const Gallery = React.lazy(() => import('../components/gallery'))
const GalleryDetails = React.lazy(() => import('../components/gallery-details'))
const DestinationList = React.lazy(() => import('../components/destination-list'));
const DestinationList2 = React.lazy(() => import('../components/destination-list-v2'));
const DestinationDetails = React.lazy(() => import('../components/destination-details'));
const UserProfile = React.lazy(() => import('../components/user-profile'))
const Thankyou = React.lazy(() => import('../pages/Thankyou/component/Thankyou'))
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy/privacy'))
const TermsNCondition = React.lazy(() => import('../pages/TermsNCondition/tnc'))


const routes = [
  { path: "/", exact: true, name: "Home", public: true, restricted: false, component: Home },
  { path: "/about", exact: true, name: "About", public: true, restricted: false, component: About },
  { path: "/contact", exact: true, name: "Contact", public: true, restricted: false, component: Contact },
  { path: "/blog", exact: true, name: "Blog", public: true, restricted: false, component: Blog },
  { path: "/blog/:id", exact: true, name: "Blog Details", public: true, restricted: false, component: BlogDetails },
  { path: "/tour", exact: true, name: "Tour List", public: true, restricted: false, component: TourList },
  { path: "/tour/:id", exact: true, name: "Tour Details", public: true, restricted: false, component: TourDetails},
  { path: "/self-planner", exact: true, name: "Self Planner", public: true, restricted: false, component: SelfPlanner},
  { path: "/profile", exact: true, name: "User Profile", public: false, restricted: false, component: UserProfile},

  //Rest of the pages
  { path: "/tour-list-v2", exact: true, name: "TourList2", public: true, restricted: false, component: TourList2},
  { path: "/tour-v3", exact: true, name: "TourList3", public: true, restricted: false, component: TourList3},
  { path: "/destination-list", exact: true, name: "DestinationList", public: true, restricted: false, component: DestinationList},
  { path: "/destination-list-v2", exact: true, name: "DestinationList2", public: true, restricted: false, component: DestinationList2},
  { path: "/destination-details", exact: true, name: "DestinationDetails", public: true, restricted: false, component: DestinationDetails},
  { path: "/gallery", exact: true, name: "Gallery", public: true, restricted: false, component: Gallery},
  { path: "/gallery-details", exact: true, name: "GalleryDetails", public: true, restricted: false, component: GalleryDetails},
  { path: "/thankyou", exact: true, name: "thankyou", public: true, restricted: false, component: Thankyou},
  { path: "/privacy-policy", exact: true, name: "privacyPolicy", public: true, restricted: false, component: PrivacyPolicy},
  { path: "/terms-and-conditions", exact: true, name: "TnC", public: true, restricted: false, component: TermsNCondition},
];

export default routes;
