export const getDate = (timeStamp) => {
  var months = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec']
  const dateTime = new Date(timeStamp);
  return(dateTime.getUTCDate() + months[dateTime.getUTCMonth()] + "' " + dateTime.getUTCFullYear().toString().substr(-2));
}


export const getBlob = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          // console.log(event.target.result)
          resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    })
}

export const strLimit = (string, length) => {
    return string.length > length ? string.substring(0, length) + '...' : string;
} 



export const AddCommaToNumber = (number) => {
  try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  catch(error) {
      console.error("Invalid format", error.message);
  }
}



export const GetRandomRating = () => {
  return (Math.random() * (5.0 - 3.5) + 3.5).toFixed(1);
}


export const HtmlToString = (html) => {
  return html.replace(/<[^>]+>/g, '');
}