import React from 'react';
import { Link } from 'react-router-dom';
import { AddCommaToNumber, GetRandomRating, HtmlToString, strLimit } from '../../../utils/Coversions';
import { Currency } from '../../../utils/Enums';

const TourList = (props) => {
    let publicUrl = process.env.PUBLIC_URL+'/';
    const packages = props.packages;
    console.log(packages)

    return	<div className="tour-list-area pd-top-120 viaje-go-top">
              <div className="container">
                <div className="row">
                    {
                        packages.map((pack, index) => {
                            return  <div className="col-lg-6" key={index}>
                                        <div className="single-destinations-list style-four">
                                            <div className="blur-thumb" style={{backgroundImage: "url('" + pack.primary_image + "')"}} /*style={{backgroundImage: 'url('+publicUrl+'assets/img/destination-list/16.png)'}}*/ />
                                            <div className="details">
                                                <div className="tp-review-meta">
                                                    <i className="ic-yellow fa fa-star" />
                                                    <i className="ic-yellow fa fa-star" />
                                                    <i className="ic-yellow fa fa-star" />
                                                    <i className="ic-yellow fa fa-star" />
                                                    <i className="fa fa-star" />
                                                    <span>{GetRandomRating()}</span>
                                                </div>
                                                <p className="location"><img src={publicUrl+"assets/img/icons/1.png"} alt="map" />{pack.title}</p>
                                                <h4 className="title"><Link to={"/tour/" + pack.package_id}>{pack.company ? pack.company : pack.company}</Link></h4>
                                                <p className="content">{pack.overview ? strLimit(HtmlToString(pack.overview), 185) : "Overview not available"}</p>
                                                <div className="list-price-meta">
                                                    <ul className="tp-list-meta d-inline-block">
                                                        <li><i className="fa fa-calendar-o" />{" " + pack.start_date}</li>
                                                        <li><i className="fa fa-clock-o" />{" " + pack.total_days + " days"}</li>
                                                        <li><i className="fa fa-star" />{GetRandomRating()}</li>
                                                    </ul>
                                                    <div className="tp-price-meta d-inline-block">
                                                        <p>Price</p>
                                                        <h2>{pack.adult_price ? AddCommaToNumber(pack.adult_price) : "NaN"}&ensp;<span>{Currency.pkr}</span></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        })
                    }
                </div>

                {/* <div className="row">
                    <div className="col-lg-12 text-md-center text-left">
                        <div className="tp-pagination text-md-center text-left d-inline-block mt-4">
                            <ul>
                                <li><a className="prev page-numbers" href><i className="la la-long-arrow-left" /></a></li>
                                <li><span className="page-numbers">1</span></li>
                                <li><span className="page-numbers current">2</span></li>
                                <li><a className="page-numbers" href>3</a></li>
                                <li><a className="page-numbers" href>4</a></li>
                                <li><a className="next page-numbers" href><i className="la la-long-arrow-right" /></a></li>
                            </ul>                          
                        </div>
                    </div>
                </div> */}
              </div>
            </div>
}

export default TourList