import React from 'react'

const TravelType = (props) => {
    return (
        <select className={props.className}>
            <option value={1}>Travel Type</option>
            <option value={2}>Event Travel</option>
            <option value={3}>Weekend Break</option>
            <option value={4}>Package Holiday</option>
            <option value={5}>Business Travel</option>
        </select>
    )
}

export default TravelType
