import { getMethod } from '../utils/Responses'

export const UserLogin = (email, password) => {
    var relativeUrl = `users/user-login?email=${email}&password=${password}`;
    return getMethod(relativeUrl);
}

export const getUserProfile=(id)=>{
var relativeUrl=`users/get-user/${id}`;
return getMethod(relativeUrl);
}

export const getListTypeDetail=()=>{
    var relativeUrl=`list-type-details`;
    return getMethod(relativeUrl);
}

export const getPackages = () => {
    var relativeUrl=`packages-list`;
    return getMethod(relativeUrl);
}

export const getPackageDetails = (id) => {
    var relativeUrl=`packages-list/${id}`;
    return getMethod(relativeUrl);
}


export const getHomePage = () => {
    var relativeUrl=`home-page/one`;
    return getMethod(relativeUrl);
}

export const getRecentUserBookings = (id) => {
    var relativeUrl=`bookings/users/${id}`;
    return getMethod(relativeUrl);
}