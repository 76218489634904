//Local Url
// const connectionString = "http://localhost:3002/api/";


//Dev Url
// const connectionString = "https://vamonosdev.herokuapp.com/api/"

//Live Url
// const connectionString = "https://vamonos.herokuapp.com/api/"

//Aws Url Dev
//const connectionString = "https://2xcar.com:4005/api/"

//Aws Url Prd
// const connectionString = "https://2xcar.com:3005/api/"

//PRD url
const connectionString = "https://api.vamonos.pk/api/";

//DEV url
//const connectionString = "https://dev-api.vamonos.pk/api/";

export { connectionString };