import * as EmailValidator from 'email-validator';
import * as PasswordValidator from 'password-validator';

export const emailValidation = (email) => {
    return EmailValidator.validate(email);
    // var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // if(regex.test(email)){
    //     return true;
    // }
    // else{
    //     return false;
    // }
};

// Minimum eight characters, at least one letter and one number
export const passwordValidation = (password) => {
    var pwdSchema = new PasswordValidator();

    pwdSchema
    .is().min(8)
    .is().max(24)
    .has().letters(1)
    .has().digits(1);


    return pwdSchema.validate(password);
    // var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    // if(regex.test(password)) {
    //     return true;
    // }
    // else {
    //     return false;
    // }
}

export const mobileValidation = (mobileNumber) => {
    var regex = /^\+\d{1,3}-\d{9,10}$/;
    if(regex.test(mobileNumber)){
        return true;
    }
    else{
        return false;
    }
};

export const nameValidation = (name) => {
    var regex = /^[a-zA-Z ]+$/;
    if(regex.test(name)){
        return true;
    }
    else{
        return false;
    }
}