import { postMethod } from '../utils/Responses'

export const UserSignup = (obj) => {
    var relativeUrl = `users`;
    return postMethod(relativeUrl, obj)
}

export const getAllFilters = (obj) => {
    var relativeUrl = `packages/filter-list`;
    return postMethod(relativeUrl, obj)
}

export const postContact = (obj) => {
    var relativeUrl = `contact-form`;
    return postMethod(relativeUrl, obj)
}

export const planMyTrip = (obj) => {
    var relativeUrl = `plan-my-trip`;
    return postMethod(relativeUrl, obj)
}

export const bookingApi = (obj) => {
    var relativeUrl = `bookings`;
    return postMethod(relativeUrl, obj)
}
export const forgotPassword=(obj)=>{
    var relativeUrl=`forgot-password`;
    return postMethod(relativeUrl,obj)
}
export const changePassword=(obj)=>{
var relativeUrl=`users/password-email`
return postMethod(relativeUrl,obj)
}
