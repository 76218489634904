import React, { Component } from 'react';

class Review extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="client-review-area client-review-area-home pd-top-70">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="thumb wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
			          <div className="client-review-thumb">
			            <img src={publicUrl+"assets/img/others/client-review.jpg" }alt="review" />
			          </div>
			        </div>
			      </div>
				  {
					  this.props.list.map((listItem, index) => {
						  return <div className="col-lg-6">
						  <div className="single-client-review wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
							<p className="sub-title">Travel Reviews</p>
							<h3 className="location-name">Kaghan Valley</h3>
							<div className="tp-review-meta">
							  <i className="ic-yellow fa fa-star" />
							  <i className="ic-yellow fa fa-star" />
							  <i className="ic-yellow fa fa-star" />
							  <i className="ic-yellow fa fa-star" />
							  <i className="fa fa-star" />
							  <span>{listItem.ratings}</span>
							</div>
							<p>{listItem.text}</p>
							<div className="media">
							  <div className="media-left">
								<img src={listItem.image ? listItem.image : publicUrl+"assets/img/avatar.jpg"} style={{objectFit: 'cover'}} alt="client" />
							  </div>
							  <div className="media-body">
								<h6 style={{fontFamily:"proxima_nova_semibold"}}>Anas Sheikh</h6>
								<p style={{fontFamily:"proxima_nova_regular"}}>CEO of TrulyFuture</p>
							  </div>
							</div>
						  </div>
						</div>
					  })
				  }
			      {/* <div className="col-lg-6">
			        <div className="single-client-review wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
			          <p className="sub-title">Travel Reviews</p>
			          <h3 className="location-name">Kaghan Valley</h3>
			          <div className="tp-review-meta">
			            <i className="ic-yellow fa fa-star" />
			            <i className="ic-yellow fa fa-star" />
			            <i className="ic-yellow fa fa-star" />
			            <i className="ic-yellow fa fa-star" />
			            <i className="fa fa-star" />
			            <span>4.0</span>
			          </div>
			          <p>Shrouded in Pakistani folklore, the landscapes of the Kaghan Valley are as enchanting as the fairy tales set here. Honey-hued cattle roam alpine pastures. Lakes are cradled by snow-capped glaciers. Of the valley's bodies of water,A place where you forget all your worries and fall in love with its nature.i found its people are also loving like this heaven. There are more than 8 lakes and sexy mountains. Whenever we plan our tour of northern areas we must go to kaghan valley first</p>
			          <div className="media">
			            <div className="media-left">
			              <img src={publicUrl+"assets/img/client/1.jpg"} alt="client" />
			            </div>
			            <div className="media-body">
			              <h6>Anas Sheikh</h6>
			              <p>CEO of TrulyFuture</p>
			            </div>
			          </div>
			        </div>
			      </div> */}
			    </div>
			  </div>
			</div>

        }
}

export default Review