import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v1 extends Component {
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
  				<footer className="footer-area" style={{backgroundImage: 'url('+publicUrl+'assets/img/bg/2.png)'}}>
				  <div className="container">
				    <div className="row">
				      <div className="col-lg-4 col-md-6">
				        <div className="footer-widget widget">
				          <div className="about_us_widget">
				            <Link to="/" className="footer-logo"> 
				              <img src={publicUrl+"assets/img/logo.png"} alt="footer logo" />
				            </Link>
				            <p style={{fontFamily:"proxima_nova_regular"}}>Our mission is to provide our clients an unforgettable experience of Pakistan's culture, its vibrant sceneries, where fairy tales meet reality in a fun and transparent way.</p>
				            <ul className="social-icon">
				              <li>
				                <a className="facebook" href="https://www.facebook.com/vamonos.pk" target="_blank" rel="noreferrer noopener"><i className="fa fa-facebook  " /></a>
				              </li>
				              <li>
				                <a className="twitter" href="https://www.instagram.com/vamonos.pk/" target="_blank" rel="noreferrer noopener"><i className="fa fa-twitter  " /></a>
				              </li>
				              <li>
				                <a className="pinterest" href="https://www.instagram.com/vamonos.pk/" target="_blank" rel="noreferrer noopener"><i className="fa fa-instagram" /></a>
				              </li>
				            </ul>
				          </div>
				        </div>
				      </div>
				      <div className="col-lg-3 col-md-6">
				        <div className="footer-widget widget ">
				          <div className="widget-contact">
				            <h4 className="widget-title">Contact us</h4>
				            <p>
				              <i className="fa fa-map-marker" /> 
				              <span style={{fontFamily:'proxima_nova_regular'}}>249-BMCHS sharfabad street-17 Karachi, Pakistan.</span>
				            </p>
				            <p className="location"> 
				              <i className="fa fa-envelope-o" />
				              <span style={{fontFamily:'proxima_nova_regular'}}>info@vamonos.pk</span>
				            </p>
				            <p className="telephone">
				              <i className="fa fa-phone base-color" /> 
				              <span style={{fontFamily:'proxima_nova_regular'}}>
				                +92 335 2254461
				              </span>
				            </p>
				          </div>
				        </div>
				      </div>
				      <div className="col-lg-2 col-md-6">
				        <div className="footer-widget widget">
				          <h4 className="widget-title">Quick Link</h4>
				          <ul className="widget_nav_menu  viaje-go-top">
				            <li style={{fontFamily:'proxima_nova_regular'}}><Link to="/">Home</Link></li>
				            <li style={{fontFamily:'proxima_nova_regular'}}><Link to="/about">About Us</Link></li>
				            {/* <li><Link to="/destination-list">Destination</Link></li> */}
				            <li><Link to="/tour">Tours</Link></li>
				            {/* <li><Link to="/blog">Blog</Link></li> */}
				            <li><Link to="/contact">Contact</Link></li>
				          </ul>
				        </div>
				      </div>
				      <div className="col-lg-3 col-md-6">
				        <div className="footer-widget widget">
				          <h4 className="widget-title" style={{fontFamily:"proxima_nova_semibold"}}>Instagram Gallery</h4>
				          <ul className="widget-instagram-feed">
				            <li><a href><img src={"https://firebasestorage.googleapis.com/v0/b/vamonos-6fb56.appspot.com/o/ContentGallery%2FUploadAdventure.jpg?alt=media&token=67a2a1ce-77c2-4efa-a554-53f9cb394705"}
							style={{width:"100px", height:"80px"}} alt="img" /></a></li>
				            <li><a href><img style={{width:"100px", height:"80px"}} src={"https://firebasestorage.googleapis.com/v0/b/vamonos-6fb56.appspot.com/o/ContentGallery%2FUploadBeachers.jpg?alt=media&token=f34b63e7-8367-4b25-939c-98272fd0b523"} alt="img" /></a></li>
				            <li><a href><img style={{width:"100px", height:"80px"}} src={"https://firebasestorage.googleapis.com/v0/b/vamonos-6fb56.appspot.com/o/ContentGallery%2FUploadCustomize.jpg?alt=media&token=82d23018-a093-4e02-9358-144b80f9399a"} alt="img" /></a></li>
				            <li><a href><img style={{width:"100px", height:"80px"}} src={"https://firebasestorage.googleapis.com/v0/b/vamonos-6fb56.appspot.com/o/ContentGallery%2FUploadDomestic.jpg?alt=media&token=a7b47f8b-b26d-4ffd-9aca-871b132f89c9"} alt="img" /></a></li>
				            <li><a href><img style={{width:"100px", height:"80px"}} src={"https://firebasestorage.googleapis.com/v0/b/vamonos-6fb56.appspot.com/o/ContentGallery%2FUploadEarth.jpg?alt=media&token=cece6c5e-63eb-4905-989a-0b1c4a6297ea"} alt="img" /></a></li>
				            <li><a href><img style={{width:"100px", height:"80px"}} src={"https://firebasestorage.googleapis.com/v0/b/vamonos-6fb56.appspot.com/o/ContentGallery%2FUploadMajestic.jpg?alt=media&token=82b5a9ad-6ad9-4262-a737-ebffd6c74be6"} alt="img" /></a></li>
				          </ul>
				        </div>
				      </div>
				    </div>
				  </div>
				  <div className="copyright-inner">
				    <div className="copyright-text">
				      © vamonos 2021 All rights reserved. Powered with by <a href="https://techaract.com/" target="_blank" rel="noreferrer noopener"><i className="fa fa-heart" /><span>Techaract.</span></a>
				    </div>
				  </div>
				</footer>


        )
    }
}


export default Footer_v1