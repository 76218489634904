import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { AddCommaToNumber, GetRandomRating } from '../../../utils/Coversions';
import { Currency } from '../../../utils/Enums';

const Offer  = (props) => {

	const list = props.list


    return  (
	<div className="offer-area pd-top-70">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-xl-6 col-lg-8">
			        <div className="section-title text-center">
			          <h2 className="title">Our Special Offers</h2>
			          <p>Discover below, your favorite destinations, exciting adventures with affordable prices.</p>
			        </div>
			      </div>
			    </div>
			  </div>
			  <div className="destinations-list-slider-bg">
			    <div className="container">
			      <div className="row">
			        <div className="col-xl-9 col-lg-10 offset-xl-1 order-lg-12">
			          <div className="destinations-list-slider">
						  {
							  list.map((listItem, index) => {
								return <div className="d-list-slider-item" key={index}>
									<div className="single-destinations-list text-center">
									<div className="thumb">
										<span className="d-list-tag">Special Offer</span>
										<img src={listItem.primary_image} alt="list" />
										<div className="d-list-btn-wrap">
										<div className="d-list-btn viaje-go-top">
											<Link className="btn btn-yellow" to={`/tour/${listItem.package_id}`}>Book Now <i className="fa fa-paper-plane" /></Link>
										</div>
										</div>
									</div>
									<div className="details">
										<h4 className="title">{listItem.company}</h4>
										<p className="content">{listItem.title}</p>
										<ul className="tp-list-meta border-bt-dot">
										<li><i className="fa fa-calendar-o" /> {listItem.departure_date}</li>
										<li><i className="fa fa-clock-o" /> {listItem.total_days} days</li>
										<li><i className="fa fa-star" /> {GetRandomRating()}</li>
										</ul>
										<div className="tp-price-meta tp-price-meta-cl">
										<p>Price</p>
										<h2>{AddCommaToNumber(listItem.adult_price)}&ensp;<small>{Currency.pkr}</small></h2>
										<del>{AddCommaToNumber(listItem.adult_price + Math.floor(Math.random() * (5000 - 500 + 1) + 500))}&ensp;<span>{Currency.pkr}</span></del>
										</div>
									</div>
									</div>
								</div>
							  })
						  }
						  {/* https://www.facebook.com/events/287276136341556/?ref=newsfeed */}
			            {/* <div className="d-list-slider-item">
			              <div className="single-destinations-list text-center">
			                <div className="thumb">
			                  <span className="d-list-tag">Special Offer</span>
			                  <img src={publicUrl+"assets/img/destination-list/1_1.png"} alt="list" />
			                  <div className="d-list-btn-wrap">
			                    <div className="d-list-btn viaje-go-top">
			                      <Link className="btn btn-yellow" to="/contact">Book Now <i className="fa fa-paper-plane" /></Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="details">
			                  <h4 className="title">Moola Chotok</h4>
			                  <p className="content">Moola Chootak Waterfalls Khuzdar</p>
			                  <ul className="tp-list-meta border-bt-dot">
			                    <li><i className="fa fa-calendar-o" /> 9apr</li>
			                    <li><i className="fa fa-clock-o" /> 2 days</li>
			                    <li><i className="fa fa-star" /> 4.3</li>
			                  </ul>
			                  <div className="tp-price-meta tp-price-meta-cl">
			                    <p>Price</p>
			                    <h2>7,499&ensp;<small>{Currency.pkr}</small></h2>
			                    <del>8,499&ensp;<span>{Currency.pkr}</span></del>
			                  </div>
			                </div>
			              </div>
			            </div> */}
						{/* https://www.facebook.com/events/2864196447159013/?ref=newsfeed */}
			            {/* <div className="d-list-slider-item">
			              <div className="single-destinations-list text-center">
			                <div className="thumb">
			                  <img src={publicUrl+"assets/img/destination-list/2.png"} alt="list" />
			                  <div className="d-list-btn-wrap">
			                    <div className="d-list-btn viaje-go-top">
			                      <Link className="btn btn-yellow" to="/contact">Book Now <i className="fa fa-paper-plane" /></Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="details">
			                  <h4 className="title">The Coastal Belt</h4>
			                  <p className="content">Kund Malir - Ormara - Gwader</p>
			                  <ul className="tp-list-meta border-bt-dot">
			                    <li><i className="fa fa-calendar-o" /> 9apr</li>
			                    <li><i className="fa fa-clock-o" /> 2 days</li>
			                    <li><i className="fa fa-star" /> 4.8</li>
			                  </ul>
			                  <div className="tp-price-meta tp-price-meta-cl">
			                    <p>Price</p>
			                    <h2>11,999&ensp;<small>{Currency.pkr}</small></h2>
			                    <del>13,500&ensp;<span>{Currency.pkr}</span></del>
			                  </div>
			                </div>
			              </div>
			            </div> */}
						{/* https://www.facebook.com/vacationnplanner/posts/140322921441964?__cft__[0]=AZU-BY7NfnvI_Qxe0GPGYD8lnrBA9bznQsgvERoYQTF9UoaFv5uELFx3MMteYb5NcTuAzg5HexOdfQgyQOz2tuNae4daExyqjxMZsT9WcTu4COs7YBmw1xzKuE0XjHTptuKAMgpmtsUtSRq-BoQ6HDwk&__tn__=%2CO%2CP-R */}
			            {/* <div className="d-list-slider-item">
			              <div className="single-destinations-list text-center">
			                <div className="thumb">
			                  <span className="d-list-tag">Special Offer</span>
			                  <img src={publicUrl+"assets/img/destination-list/3.png"} alt="list" />
			                  <div className="d-list-btn-wrap">
			                    <div className="d-list-btn viaje-go-top">
			                      <Link className="btn btn-yellow" to="/contact">Book Now <i className="fa fa-paper-plane" /></Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="details">
			                  <h4 className="title">Hunza & Naltar Valley</h4>
			                  <p className="content">Land of Lakes</p>
			                  <ul className="tp-list-meta border-bt-dot">
			                    <li><i className="fa fa-calendar-o" /> 16may</li>
			                    <li><i className="fa fa-clock-o" /> 6 days</li>
			                    <li><i className="fa fa-star" /> 4.6</li>
			                  </ul>
			                  <div className="tp-price-meta tp-price-meta-cl">
			                    <p>Price</p>
			                    <h2>23,000&ensp;<small>{Currency.pkr}</small></h2>
			                    <del>25,000&ensp;<span>{Currency.pkr}</span></del>
			                  </div>
			                </div>
			              </div>
			            </div> */}
						{/* https://www.facebook.com/events/1550614481815397/?ref=newsfeed */}
			            {/* <div className="d-list-slider-item">
			              <div className="single-destinations-list text-center">
			                <div className="thumb">
			                  <span className="d-list-tag">Most Availed</span>
			                  <img src={publicUrl+"assets/img/destination-list/1.png"} alt="list" />
			                  <div className="d-list-btn-wrap">
			                    <div className="d-list-btn viaje-go-top" >
			                      <Link className="btn btn-yellow" to="/contact">Book Now <i className="fa fa-paper-plane" /></Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="details">
			                  <h4 className="title">Our Sindh</h4>
			                  <p className="content">Pakistan Day Special</p>
			                  <ul className="tp-list-meta border-bt-dot">
			                    <li><i className="fa fa-calendar-o" /> 23mar</li>
			                    <li><i className="fa fa-clock-o" /> 1 day</li>
			                    <li><i className="fa fa-star" /> 4.3</li>
			                  </ul>
			                  <div className="tp-price-meta tp-price-meta-cl">
			                    <p>Price</p>
			                    <h2>3,499&ensp;<small>{Currency.pkr}</small></h2>
			                    <del>4,999&ensp;<span>{Currency.pkr}</span></del>
			                  </div>
			                </div>
			              </div>
			            </div> */}
			          </div>
			        </div>
			        <div className="col-lg-2 align-self-center order-lg-11">
			          <div className="container">
			            <div className="destinations-slider-controls">
			              <div className="slider-nav tp-control-nav" />
			              {/*slider-nav*/}
			              <div className="tp-slider-extra slider-extra">
			                <div className="text">
			                  <span className="first">01 </span>
			                  <span className="last" />
			                </div>
			                {/*text*/}
			                <div className="d-list-progress" role="progressbar" aria-valuemin={0} aria-valuemax={100}>
			                  <span className="slider__label sr-only" />
			                </div>
			              </div>
			              {/*slider-extra*/}
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>)
}

export default Offer