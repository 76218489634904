import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Intro extends Component {

    render() {


			    return  <div className="intro-area pd-top-112">
				  <div className="container">
				    <div className="row">
				      <div className="col-md-4">
				        <div className="single-intro wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
				          <h4 className="intro-title">
				            <span className="intro-count">
							01</span>
				            <Link className="intro-cat" to="#">Travel</Link>
				          </h4>
				          <p>Travel to far and beyond, capture moments of clarity, feed your wanderlust with new and amazing places.</p>
				        </div>
				      </div>
				      <div className="col-md-4">
				        <div className="single-intro wow animated fadeInUp" data-wow-duration="1.0s" data-wow-delay="0.2s">
				          <h4 className="intro-title">
				            <span className="intro-count">02</span>
				            <Link className="intro-cat" to="#">Experience</Link>
				          </h4>
				          <p>Experience new and exciting adventures, discover the beauty of Pakistan highlight the true meaning of travel and life.</p>
				        </div>
				      </div>
				      <div className="col-md-4">
				        <div className="single-intro wow animated fadeInUp" data-wow-duration="1.6s" data-wow-delay="0.3s">
				          <h4 className="intro-title">
				            <span className="intro-count">03</span>
				            <Link className="intro-cat" to="#">Relax</Link>
				          </h4>
				          <p>Pack your bags, open your heart and mind, and head to the unknown places of Pakistan, amidst the aesthetic Mountains and calming beaches.</p>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>

        }
}

export default Intro