import React from 'react';
import Navbar from '../../../components/global-components/navbar';
import Footer from '../../Footer'
import NotFound from '../../../pages/NotFound';
import ScrollToTop from '../../../navigation/ScrollToTop';

import { Switch } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "../../../navigation/RouteTypes";
import routes from "../../../navigation/Routes";
import { Subscribe } from '../../SectionComponents';

const DefaultLayout = () => {
    return (
        <div>
            
            <ScrollToTop />

            <Navbar />
                <Switch>
                    {routes.map((route, idx) => {
                    return route.component ? (
                        route.public ? (
                        <PublicRoute
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            restricted={route.restricted}
                            component={route.component}
                        />
                        ) : (
                        <PrivateRoute
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            component={route.component}
                        />
                        )
                    ) : null;
                    })}
                    <PublicRoute component={NotFound} />
                </Switch>
            <Subscribe />
            <Footer />
        </div>
    )
}

export default DefaultLayout

