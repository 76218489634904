import React, { Component } from 'react';
import { Currency } from '../../../utils/Enums';
import { AddCommaToNumber, GetRandomRating } from '../../../utils/Coversions';

class Banner extends Component {

    render() {

    return  <div className="banner-area viaje-go-top">
			  <div className="banner-slider">
				{/* https://www.facebook.com/events/499697641216335/?ref=newsfeed */}
				{
					this.props.list ? this.props.list.slice(0, 3).map((listItem, index) => {
							return <div className={"banner-slider-item banner-bg-" + (index + 1)}>
							<div className="container">
								<div className="row">
								<div className="col-xl-8 col-lg-9 offset-xl-2 offset-lg-1">
									<div className="row">
									<div className="col-lg-9 col-sm-8">
										<div className="banner-inner">
										<p  className="banner-cat s-animate-1" style={{fontFamily:"proxima_nova_semibold"}}>{listItem.company}</p>
										<h2 className="banner-title s-animate-2" style={{fontFamily:"gilroy_bold", fontSize:"50px"}}>{listItem.title}</h2>
										</div>
									</div>
									<div className="col-lg-3 col-sm-4">
										{/* <div className="video-popup-btn s-animate-video">
										<a href="https://www.youtube.com/watch?v=c7XEhXZ_rsk" className="video-play-btn mfp-iframe"><i className="fa fa-play" /></a>
										</div> */}
									</div> 
									<div className="col-lg-12 banner-tour-package">
										<div className="row">
										<div className="col-sm-4 s-animate-3">
											<div className="tp-price-meta">
											<p style={{fontFamily:"proxima_nova_regular"}}>Price</p>
											<h2>{AddCommaToNumber(listItem.adult_price + Math.floor(Math.random() * (5000 - 500 + 1) + 500))}&ensp;<small>{Currency.pkr}</small></h2>
											<p className="tp-price-meta-details" style={{fontFamily:"proxima_nova_regular"}}>{listItem.total_days} Days and {listItem.total_nights} Nights {/*<span>on 2 persons</span>*/}</p>
											</div>
										</div>
										<div className="col-sm-4 s-animate-4">
											<div className="tp-price-meta">
											<p style={{fontFamily:"proxima_nova_regular"}}>Luxurious</p>
											<h2 style={{fontFamily:"proxima_nova_regular"}}>Trip</h2>
											<p className="tp-price-meta-details" style={{fontFamily:"proxima_nova_regular"}}>{listItem.luxurious} {/*<span>2 persons</span>*/}</p>
											</div>
										</div>
										<div className="col-sm-4 s-animate-5">
											<div className="tp-price-meta">
											<p style={{fontFamily:"proxima_nova_regular"}}>Departure date</p>
											<h2 style={{fontFamily:"proxima_nova_regular"}}>{listItem.departure_date.split(' ')[0]}</h2>
											<p className="tp-price-meta-details" style={{fontFamily:"proxima_nova_regular"}}>{listItem.departure_date.split(' ')[1]} {/*<span>or later</span>*/}</p>
											</div>
											</div> 
										</div> 
									</div>
									</div>
								</div>
								</div>
							</div>
						</div>
					}) : <div className={"banner-slider-item banner-bg-temp"}>
					<div className="container">
						<div className="row">
						<div className="col-xl-8 col-lg-9 offset-xl-2 offset-lg-1">
							<div className="row">
							<div className="col-lg-9 col-sm-8">
								<div className="banner-inner">
								<p className="banner-cat s-animate-1" style={{fontFamily:"proxima_nova_regular"}}>{"Loading..."}</p>
								<h2 className="banner-title s-animate-2" style={{fontFamily:"proxima_nova_regular"}}>{"Loading..."}</h2>
								</div>
							</div>
							<div className="col-lg-3 col-sm-4">
								{/* <div className="video-popup-btn s-animate-video">
								<a href="https://www.youtube.com/watch?v=c7XEhXZ_rsk" className="video-play-btn mfp-iframe"><i className="fa fa-play" /></a>
								</div> */}
							</div> 
							<div className="col-lg-12 banner-tour-package">
								<div className="row">
								<div className="col-sm-4 s-animate-3">
									<div className="tp-price-meta">
									<p style={{fontFamily:"proxima_nova_regular"}}>Price</p>
									{/* <h2>{AddCommaToNumber(listItem.adult_price + Math.floor(Math.random() * (5000 - 500 + 1) + 500))}&ensp;<small>{Currency.pkr}</small></h2> */}
									<p className="tp-price-meta-details">{"Loading..."}</p>
									</div>
								</div>
								<div className="col-sm-4 s-animate-4">
									<div className="tp-price-meta">
									<p style={{fontFamily:"proxima_nova_regular"}}>Luxurious</p>
									<h2 style={{fontFamily:"proxima_nova_regular"}}>Trip</h2>
									<p className="tp-price-meta-details"> {/*<span>2 persons</span>*/}</p>
									</div>
								</div>
								<div className="col-sm-4 s-animate-5">
									<div className="tp-price-meta">
									<p style={{fontFamily:"proxima_nova_regular"}}>Departure date</p>
									<h2>{}</h2>
									<p className="tp-price-meta-details" style={{fontFamily:"proxima_nova_regular"}}>{/*<span>or later</span>*/}</p>
									</div>
									</div> 
								</div> 
							</div>
							</div>
						</div>
						</div>
					</div>
				</div>
				}
			    
			  </div>
			  <div className="banner-social-meta">
			    <div className="banner-slider-dots" />
			    <ul className="social-icon">
			      <li>
			        <a className="facebook" href="https://www.facebook.com/vamonos.pk" target="_blank" rel="noreferrer noopener"><i className="fa fa-facebook" /></a>
			      </li>
			      <li>
			        <a className="twitter" href="https://www.instagram.com/vamonos.pk/" target="_blank" rel="noreferrer noopener"><i className="fa fa-twitter" /></a>
			      </li>
			      <li>
			        <a className="pinterest" href="https://www.instagram.com/vamonos.pk/" target="_blank" rel="noreferrer noopener"><i className="fa fa-instagram" /></a>
			      </li>
			    </ul>
			  </div> 
			  <div className="container">
			    <div className="banner-slider-controls">
			      <div className="slider-nav tp-control-nav" />
			      {/*slider-nav*/}
			      <div className="tp-slider-extra slider-extra">
			        <div className="text">
			          <span className="first">01</span>
			          <span className="devider">/</span>
			          <span className="last" />
			        </div>
			      </div>
			      {/*slider-extra*/}
			    </div>
			  </div>
			</div>
        }
}

export default Banner