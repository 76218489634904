import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import { emailValidation }  from '../../../utils/Validations';
import { UserLogin } from '../../../apis/GetMethods';
import { login } from '../../../config/LoginAuth';
import { Link } from 'react-router-dom';


const Login = () => {
    const [loading, setLoading] = useState(false);

    let publicUrl = process.env.PUBLIC_URL+'/'

    useEffect(() => {
        const $ = window.$;
    
        $(window).on('load', function () {
            console.log("chala");
            var preLoder = $("#preloader");
            preLoder.fadeOut(0);

       });
    },[])

    const handleLogin = (e) => {
        e.preventDefault();
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
    
        if(emailValidation(email)){
            setLoading(true);
            UserLogin(email, password).then(doc => {
                if(doc.code === 1) {
                    localStorage.setItem('userId',doc.user_id);
                    localStorage.setItem('userToken',doc.token);
                    // localStorage.setItem('userInfo',doc);
                    login(doc);
                    setLoading(false);
                }
                else {
                    document.getElementById('error-label').innerHTML = "Login Failed: Incorrect email or password";
                    clearFields();
                    setLoading(false);
                }
            })
            .catch(error => {
                document.getElementById("error-label").innerHTML = error.message;
                setLoading(false);
            });
        }
        else {
            document.getElementById("error-label").innerHTML = "You have entered an invalid email address";
            setLoading(false);
        }
    }

    function clearFields() {
        document.getElementById("email").value = "";
        document.getElementById("password").value = "";
    }

    return <>
        <Container fluid>
            <Row>
                <Col md="7" className="px-0 d-none d-md-block">
                    <div className="brand-wrapper">
                        <Link to="/">
                            <img src={publicUrl + 'assets/img/logo.png'} alt="Vamonos logo" className="logo img-fluid"/>
                        </Link>
                    </div>
                    <img src={publicUrl + 'assets/img/loginBg.jpg'} alt="Ushu forest" className="login-img"/>
                </Col>

                <Col md="5" id="vamonos-signup">
                    <div className="login-wrapper">
                        <h1 className="login-title" style={{fontFamily:"gilroy_bold"}}>Log in</h1>
                        <p style={{fontFamily:"proxima_nova_regular"}}>Please Login To Get Best Experience</p>
                        
                        <Form onSubmit={(e) => handleLogin(e)}>
                            <FormGroup>
                                <Label for="email"><EmailIcon style={{marginBottom : '-3px ' , fontSize :  "15px"}}/>Email</Label>
                                <Input type="email" name="email" id="email" placeholder="email@example.com" required
                                    onKeyPress={() => { document.getElementById("error-label").innerHTML = ""; }}
                                />
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Label for="password"><LockIcon style={{marginBottom : '-2px', fontSize :  "15px"}}/>Password</Label>
                                <Input type="password" name="password" id="password" placeholder="•••••••••••" required
                                    onKeyPress={() => { document.getElementById("error-label").innerHTML = ""; }}
                                />
                            </FormGroup>
                            <div id="error-label" className="text-danger" style={{fontSize: '14px'}}></div>
                            
                           <Link to='./forgotpassword'><p style={{fontFamily:"proxima_nova_regular"}}>
                                <a href className="float-right mb-3">Forgot password?</a>
                            </p></Link> 
                            <Button className="login-btn" type="submit" color="info" block disabled={loading ? true : false}>{loading ? "Please wait..." : "Login"}</Button>
                            <p style={{fontFamily:"proxima_nova_regular"}}>Don't have an account?
                                <a href="/register"> Register here</a>
                            </p>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
}
export default Login;