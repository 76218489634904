const TOKEN_KEY = "LOGIN_TOKEN";
const userToken = "userToken";
const userId = "userId"
const userInfo = "userInfo"

export const login = (userId) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(userId));
  window.location.reload();
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  window.location.reload();
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

export const getUserId = () => {
  return JSON.parse(localStorage.getItem(TOKEN_KEY));
};

export const getRememberMe = () => {
  return localStorage.getItem("isRememberMe");
};

export const getRememberMeEmail = () => {
  return localStorage.getItem("rememberMeEmail");
};

export const getRememberMePwd = () => {
  return localStorage.getItem("rememberMePwd");
};

export const setRememberMe = (isRememberMe, rememberMeEmail, rememberMePwd) => {
  localStorage.setItem("isRememberMe", isRememberMe);
  localStorage.setItem("rememberMeEmail", rememberMeEmail);
  localStorage.setItem("rememberMePwd", rememberMePwd);
};

export const removeRememberMe = () => {
  localStorage.removeItem("isRememberMe");
  localStorage.removeItem("rememberMeEmail");
  localStorage.removeItem("rememberMePwd");
};
