import React, {  useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Button, Label, Input } from 'reactstrap';
import EmailIcon from '@material-ui/icons/Email';
import {forgotPassword} from '../../apis/PostMethods';
import { emailValidation }  from '../../utils/Validations';
import { useHistory } from 'react-router-dom'

import { Link } from 'react-router-dom';


const ForgetPassword = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let publicUrl = process.env.PUBLIC_URL+'/'


    const resetPassword = (e) => {
        e.preventDefault();
    
        let email  = document.getElementById('email').value
    
    
        if(emailValidation(email)) {
          setLoading(true);
          forgotPassword({email}).then(res => {
            setLoading(false);
            if (res.code === 1) {
                console.log(res)
                //alert("done")
                history.push('/changepassword',email)
            }
            else {
              document.getElementById('error-label').innerHTML = res.message;
            }
          }).catch(err => {
            setLoading(false);
            document.getElementById("error-label").innerHTML = err.message;
          })
        }
        else {
          document.getElementById("error-label").innerHTML = "You have entered an invalid email address";
          setLoading(false);
        }
      }
    

    return <>
        <Container fluid>
            <Row>
                <Col md="7" className="px-0 d-none d-md-block">
                    <div className="brand-wrapper">
                        <Link to="/">
                            <img src={publicUrl + 'assets/img/logo.png'} alt="Vamonos logo" className="logo img-fluid"/>
                        </Link>
                    </div>
                    <img src={publicUrl + 'assets/img/loginBg.jpg'} alt="Ushu forest" className="login-img"/>
                </Col>

                <Col md="5" id="vamonos-signup">
                    <div className="login-wrapper">
                        <h1 className="login-title" style={{fontFamily:"gilroy_bold"}}>Reset Password</h1>
                        <p style={{fontFamily:"proxima_nova_regular"}}>Request for reset Password</p>
                        
                        <Form onSubmit={(e) => resetPassword(e)}>
                            <FormGroup>
                                <Label for="email"><EmailIcon style={{marginBottom : '-3px ' , fontSize :  "15px"}}/>Email</Label>
                                <Input type="email" name="email" id="email" placeholder="email@example.com" required
                                    onKeyPress={() => { document.getElementById("error-label").innerHTML = ""; }}
                                />
                            </FormGroup>
                            <div id="error-label" className="text-danger" style={{fontSize: '14px'}}></div>
                           
                            <Button className="login-btn" type="submit" color="info" block disabled={loading ? true : false}>{loading ? "Please wait..." : "Send"}</Button>
                           
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
}
export default ForgetPassword;