import { connectionString } from '../config/ConnectionString';
const axios = require("axios");

const getMethod = (relativeUrl) => {
  var url = connectionString + relativeUrl;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(function (response) {
        // handle success
        const data = response.data.results;
        resolve(data);
      })
      .catch(function (error) {
        // handle error
        let updatedData = false;
        resolve(updatedData);
      });
    });
};

const postMethod = (relativeUrl, obj) => {
  var url = connectionString + relativeUrl;
  return new Promise((resolve, reject) => {
    
    axios
      .post(url, obj)
      .then(function (response) {
        const data = response.data.results;
        resolve(data);
      })
      .catch(function (error) {
        let updatedData = false;
        resolve(updatedData);
      });
  });
};

const updateMethod = (relativeUrl, obj) => {
  var url = connectionString + relativeUrl;
  return new Promise((resolve, reject) => {
    axios
      .patch(url, obj)
      .then(function (response) {
        // handle success
        const data = response.data.results;
        resolve(data);
      })
      .catch(function (error) {
        // handle error
        let updatedData = error;
        resolve(updatedData);
      });
  });
};

const deleteMethod = (relativeUrl) => {
  var url = connectionString + relativeUrl;
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then(function (response) {
        // handle success
        const data = response.data.results;
        resolve(data);
      })
      .catch(function (error) {
        // handle error
        let updatedData = error;
        resolve(updatedData);
      });
  });
};

export { getMethod, postMethod, updateMethod, deleteMethod }
