import React, { Component } from 'react';
import { GetRandomRating } from '../../../utils/Coversions';
import { Currency } from '../../../utils/Enums';

class HolidayPlan extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="holiday-plan-area tp-holiday-plan-area mg-top-96" style={{backgroundImage: 'url('+publicUrl+'assets/img/bg/8.png)'}}>
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-xl-6 col-lg-9">
			        <div className="section-title text-center">
			          <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Our Trusted Vendors</h2>
			          <p className="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">Here are your favorite tour vendors all in one place so explore, choose, and vamonos!</p>
			        </div>
			      </div>
			    </div>
			    <div className="row">
					{
						this.props.list.map((listItem, index) => {
							return <div className="col-lg-2 col-sm-3" key={index}>
								<div className="single-destinations-list style-two wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
								<div className="thumb" style={{border: '1px solid #d3d3d363', background: 'none'}}>
									<img src={listItem.profile_pic} alt="list" />
								</div>
								<div className="details">
									<p className="location"><img src={publicUrl+"assets/img/icons/1.png"} alt="map" />Pakistan</p>
									<h4 className="title">{listItem.company}</h4>
									<p className="content">{listItem.bio}</p>
									<div className="tp-price-meta">
									<h2 style={{fontSize: '20px'}}>
										<i className="fas fa-star"></i>
										<i className="fas fa-star"></i>
										<i className="fas fa-star"></i>
										<i className="fas fa-star"></i>
										<i className="far fa-star"></i>
										&ensp;
										&ensp;
										<small style={{marginTop: '3px'}}>{"4.0"}</small>
										{/* 25,000&ensp;<small>{Currency.pkr}</small> */}
									</h2>
									</div>
								</div>
								</div>
							</div>
						})
					}
			      {/* <div className="col-lg-3 col-sm-6">
			        <div className="single-destinations-list style-two wow animated fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/destination-list/4.png"} alt="list" />
			          </div>
			          <div className="details">
			            <p className="location"><img src={publicUrl+"assets/img/icons/1.png"} alt="map" />Kashmir</p>
			            <h4 className="title">Bon Voyage</h4>
			            <p className="content">7 Days Tour on 1 person</p>
			            <div className="tp-price-meta">
			              <h2>25,000&ensp;<small>{Currency.pkr}</small></h2>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3 col-sm-6">
			        <div className="single-destinations-list style-two wow animated fadeInUp" data-wow-duration="0.7s" data-wow-delay="0.2s">
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/destination-list/5.png"} alt="list" />
			          </div>
			          <div className="details">
			            <p className="location"><img src={publicUrl+"assets/img/icons/1.png"} alt="map" />Hunza Valley</p>
			            <h4 className="title">vamonos</h4>
			            <p className="content">4 days 2 person</p>
			            <div className="tp-price-meta">
			              <h2>24,000&ensp;<small>{Currency.pkr}</small></h2>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3 col-sm-6">
			        <div className="single-destinations-list style-two wow animated fadeInUp" data-wow-duration="0.9s" data-wow-delay="0.3s">
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/destination-list/6.png"} alt="list" />
			          </div>
			          <div className="details">
			            <p className="location"><img src={publicUrl+"assets/img/icons/1.png"} alt="map" />Hunza Valley</p>
			            <h4 className="title">Vacation Planners</h4>
			            <p className="content">4 days 4 person</p>
			            <div className="tp-price-meta">
			              <h2>50,000&ensp;<small>{Currency.pkr}</small></h2>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3 col-sm-6">
			        <div className="single-destinations-list style-two wow animated fadeInUp" data-wow-duration="1.1s" data-wow-delay="0.4s">
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/destination-list/7.png"} alt="list" />
			          </div>
			          <div className="details">
			            <p className="location"><img src={publicUrl+"assets/img/icons/1.png"} alt="map" />Quetta</p>
			            <h4 className="title">Bon Voyage</h4>
			            <p className="content">4 days 2 person</p>
			            <div className="tp-price-meta">
			              <h2>25,000&ensp;<small>{Currency.pkr}</small></h2>
			            </div>
			          </div>
			        </div>
			      </div> */}
			    </div>
			  </div>
			</div>

        }
}

export default HolidayPlan